import * as React from "react"
import Layout from "../components/layout"
import Helmet from 'react-helmet'
import useIntersection from '../components/useIntersection'; // import the custom hook
import classNames from 'classnames';

const Section = ({  id, className, children }) => {
  const [ref, isIntersecting] = useIntersection({
    root: null, // Use the viewport as the root
    rootMargin: '0px',
    threshold: 0.4 // Trigger when 50% of the element is in view
  });

  return (
    <section
      id={id}
      ref={ref}
      className={classNames(className, { 'not-in-view': !isIntersecting })}
    >
      {children}
    </section>
  );
};


const AboutPage = () => {
  return (
  <>
<Helmet
    bodyAttributes={{
        class: 'about-page whitemain'
    }}
/>
  <Layout pageTitle="About">
    <Section id="main">
      <div id="about-intro">
        <div className="left-col">
          <h1 className="reveal-textDIS">
          <span className="revealblue"> Tobstar was launched in 2001 by Toby Pattullo, with the same vision as today. We are a distinguished web development company, forging partnerships with agencies and designers across Australia.</span></h1>
          <p><span className="revealblack">At Tobstar, our dedicated team specialises in crafting high-quality digital media and delivering innovative programming solutions.</span></p>
        <p><span className="revealblack clone">We pride ourselves on producing exceptional work that not only meets but exceeds the expectations of our clients and partner agencies. Our firm commitment is to consistently provide unparalleled user experiences and leverage the full potential of digital technology.</span></p>
       <p><span className="revealblack clone">In addition to our work, I invite you to learn more about my background and journey in an exclusive interview featured on The Australian Sporting Alliance for People with a Disability. This interview highlights my experiences and interests, providing a personal insight into how these aspects have shaped Tobstar's commitment to excellence in digital technology. Discover more about my journey <a href="https://asapd.org/qa-with-toby-pattullo/" rel="noreferrer" target="_blank">here</a>.</span></p>
        </div>
        <div className="right-col">
            <img className="picture" src={'/img/about/5351-tobstar.jpg'} width="640" alt="Two Bays 56km Bib Tobstar" />
        </div>
      </div>
    </Section>

    <Section id="about-content">
      <div id="inner-content">

       <h2>We are a small web development company, why us?</h2>

        <ul>
          <li>We are lead thinkers and builders in engineering websites and apps that change user experience and motivate in greater return.</li>
          <li>We are flexible to changes, trends and market needs and provide on-going solutions and can adapt quick changes from feedback and suggestions.</li>
          <li>We offer personalised support and our expertise. We actually look into any issues specifically and will try to help solve a problem or offer a solution.</li>
          <li>We respond quickly and we aren’t run by management structure. Ideas, feedback, and suggestions all go straight to full stack web developer (who is principal of Tobstar) to be addressed immediately or corrected quickly, without going through endless channels.</li>
          <li>We also give personal attention to our clients directly and build authentic relationships.</li>
          <li>We are the underdogs. With a hand picked team, experience and a natural chemistry in project collaboration we’re highly productive.</li>
         <li>We are a reliable team with no turnaround. Collaborating with individual talents such as designers, digital strategists and media communications.</li>
          <li>We’re always thinking outside the box to attract new visitors and users with limited marketing funds. We care about our website’s security, fast performance and accessibilities.</li>
          <li>Our pricing is very flexible and competitive with our low overheads.</li>
        </ul>

      </div>
    </Section>
</Layout>

  </>
  )
}

export default AboutPage


export const Head = () => (
  <>
    <title>About - Tobstar</title>
    <meta name="description" content="Tobstar was launched in 2001 by the current owner, with the same vision as today. We are a web development company, collaborating with creative teams throughout Asia and Australia. Our team are specialists in high quality digital media and achieve creative programming solutions." />
  </>
)